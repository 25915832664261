/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
import routes from '../config/routes';

export default function (initialState) {
  const { permission } = initialState;

  return{
    ...permission.reduce((pre, cur) => {
      pre[cur] = true;
      return pre;
    }, {}),
  };
}
