import {Button, Result} from 'antd';
import React from 'react';
import {history} from 'umi';
import access from "@/access";

const UnAccessible = () => {
  // 首页无权限时跳转其他页面【可多次判断】 TODO
  // if (!access()['首页权限']) history.push('其他页面路由');
  return (<Result
    status="403"
    title="403"
    subTitle="Sorry, you have no permission to visit the page."
    extra={
      <Button type="primary" onClick={() => history.push('/')}>
        Back Home
      </Button>
    }
  />)
};

export default UnAccessible;
