// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/login/",
        "layout": false,
        "name": "登录",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LoginManage' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/LoginManage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/",
        "redirect": "/scp/index/",
        "exact": true
      },
      {
        "path": "/scp/index/",
        "name": "首页",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Index' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/Index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/my/",
        "name": "我的",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__My' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/My'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/info/",
        "name": "个人信息",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__My__components__UserInfo' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/My/components/UserInfo'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/approvemanage/uploadapprove/",
        "name": "程序上传审批",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UploadApproval' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/UploadApproval'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/approvemanage/downloadapprove/",
        "name": "程序下发审批",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DownloadApproval' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/DownloadApproval'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/approvemanage/commissionapprove/",
        "name": "在线调试审批",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OnLineApproval' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/OnLineApproval'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/approvemanage/specialapproval/",
        "name": "特批调试审批",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SpecialApproval' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/SpecialApproval'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/approvemanage/equiplogoffapprove/",
        "name": "设备注销审批",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipLogoffApproval' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/EquipLogoffApproval'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/approvemanage/softwareuploadapprove/",
        "name": "调试软件上传审批",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SoftwareUploadApproval' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/SoftwareUploadApproval'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/personalapply/uploadapply/",
        "name": "程序上传申请",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UploadApply' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/UploadApply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/personalapply/downloadapply/",
        "name": "程序下发申请",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__DownloadApply' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/DownloadApply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/personalapply/commissionapply/",
        "name": "在线调试申请",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__OnLineApply' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/OnLineApply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/approvemanage/equiplogoffapply/",
        "name": "设备注销申请",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__EquipLogoffApply' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/EquipLogoffApply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/approvemanage/specialapply/",
        "name": "特批调试申请",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SpecialApply' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/SpecialApply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/approvemanage/softwareuploadapply/",
        "name": "调试软件上传申请",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SoftwareUploadApply' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/SoftwareUploadApply'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/createkey/",
        "name": "密钥生成",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Createkey' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/Createkey'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/myconnectkey/",
        "name": "我的连接码",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__MyConnectKey' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/MyConnectKey'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/scp/genneratorcode/",
        "name": "特批调试授权",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__GenneratorCode' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/GenneratorCode'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/initsystem/",
        "layout": false,
        "name": "初始化",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Createkey' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/Createkey'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/index.html",
        "redirect": "/scp/",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/scp/",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/jenkins/agent/workspace/前端软件安全管控平台项目/测试环境/front/security-wechat/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
