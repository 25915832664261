import { PageLoading } from '@ant-design/pro-layout';
import { SmileOutlined, HeartOutlined } from '@ant-design/icons';
import { SettingDrawer } from '@ant-design/pro-layout';
import access from '@/access';
import defaultSettings from '../config/defaultSettings';
import logo from '@/assets/logo.svg';

import UnAccessible from '@/pages/403';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import watermark from '@/assets/watermark.png';
import { history } from 'umi';
import { getPermission, getSystemStatus, getUserInfo } from './services/api';
import { ConfigProvider } from 'antd';

export const initialStateConfig = {
  loading: <PageLoading />,
};

const createInitialState = (
  initializationCompleted,
  permission,
  settings,
  getInitStateFunc,
  commonTitle,
  current,
) => ({
  permission,
  initializationCompleted,
  settings,
  getInitState: getInitStateFunc,
  current,
  commonTitle,
});

const getInitState = async () => {
  const settings = defaultSettings;
  const commonTitle = settings.title;
  const getInitStateFunc = getInitState;

  const { code, data } = await getUserInfo();
  if (code !== 0) {
    return createInitialState(false, [], settings, getInitStateFunc, commonTitle);
  }
  const { code: code1, data: permission } = await getPermission({ userId: data.userId });
  if (code1 !== 0) {
    return createInitialState(false, [], settings, getInitStateFunc, commonTitle);
  }
  const { code: cd, data: dt } = await getSystemStatus();

  if (cd !== 0 && cd !== undefined) {
    return createInitialState(false, [], settings, getInitStateFunc, commonTitle);
  }
  if (!dt.ifAllKeyInitialized) {
    history.replace('/initsystem/');
    return createInitialState(
      false,
      permission.map((i) => i.code),
      settings,
      getInitStateFunc,
      commonTitle,
      data,
    );
  }
  return createInitialState(
    true,
    permission.map((i) => i.code),
    settings,
    getInitStateFunc,
    commonTitle,
    data,
  );
};
/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */

export async function getInitialState() {
  try {
    if (
      history.location.pathname !== '/login/' &&
      history.location.pathname !== '/scp/initsystem/'
    ) {
      return await getInitState();
    }
  } catch (error) {
    console.log(error);
  }
  return {
    permission: [],
    initializationCompleted: false,
    getInitState,
    settings: defaultSettings,
    commonTitle: defaultSettings.title,
  };
} // ProLayout 支持的api https://procomponents.ant.design/components/layout

/**
 * 自定义菜单
 * */
const iconMap = {
  SmileOutlined: <SmileOutlined />,
  HeartOutlined: <HeartOutlined />,
};

const menuDataRender = (menus) =>
  menus.map(({ icon, routes, ...item }) => {
    if (item.name && access()[item.access]) {
      // 判断权限
      return {
        ...item,
        // 方案一： 此用法会把所有的图标库引入，造成增加工程3M大小，请谨用
        // icon: icon && React.createElement(Icon[icon]),
        // 方案二： 建议用此方案，只引入用到的图标，避免图标库全部引入
        // icon: icon && iconMap [icon],
        // 方案三： 自定义图标
        icon:
          item.path.split('/').length == 2 ? (
            <span className="anticon">
              <img
                src={require('@/assets/menu/' + item.name + '.svg')}
                style={{
                  height: '14px',
                  width: '14px',
                  float: 'left',
                }}
              />
            </span>
          ) : (
            ''
          ),
        routes: routes && menuDataRender(routes),
      };
    }
  });

// 获取权限
// const getPermission = async (cb) => {
//   const auth = await getPermissionList({
//     id: '',
//     permissionValue: '',
//     limit: 'all',
//   });
//   if (auth?.code == 0) {
//     let permissionArray = auth?.data?.map((item) => item.permissionValue) || [];
//     localStorage.setItem('antd-pro-authority', JSON.stringify(permissionArray));
//     cb(); // 更新权限状态
//   } else {
//     console.error('权限获取失败！');
//   }
// };

export const layout = ({ initialState, setInitialState }) => {
  return {
    rightContentRender: () => null,
    contentStyle: { padding: 0, margin: 0 },
    disableContentMargin: false,
    waterMarkProps: {
      // 页面使用PageContainer组件，自动显示水印
      content: '北京万集科技股份有限公司', // 显示文字，删除opacity
      image: watermark, // 优先显示图片
      fontSize: 20,
      rotate: -20,
      height: 64,
      width: 200,
      gapX: 210,
      gapY: 150,
      markStyle: {
        opacity: 0.1,
      },
    },
    headerRender: (props) => {
      return <Header routes={props.route.children} />;
    },
    breadcrumbRender: false, // 取消面包屑
    pageTitleRender: false, // 取消渲染标题区域
    menuHeaderRender: false,
    menuDataRender: undefined,
    footerRender: () => <Footer />,
    logo: logo,
    onPageChange: () => {
      if (
        initialState.current &&
        !initialState.initializationCompleted &&
        history.location.pathname !== '/initsystem/'
      ) {
        console.log(initialState)
        history.push('/initsystem/');
      }
    }, //页面切换时发生的操作
    links: [], // 菜单最下方链接
    // 自定义 403 页面
    unAccessible: <UnAccessible />,
    // 增加一个 loading 的状态
    // childrenRender: (children, props) => {
    //   return (

    //       {children}
    //       {/* {process.env.NODE_ENV === 'development' && !props.location?.pathname?.includes('/login') && (
    //         <SettingDrawer
    //           enableDarkTheme
    //           settings={initialState?.settings}
    //           onSettingChange={(settings) => {
    //             setInitialState((preInitialState) => ({...preInitialState, settings}));
    //           }}
    //         />
    //       )} */}

    //   );
    // },
    ...initialState?.settings,
  };
};
