//自动获取地址
const autoAddress = () => {
  let ip = window.location.protocol + '//' + window.location.hostname + ':';
  if (process.env.NODE_ENV === 'development') {// 开发时各个项目地址
    return {
      pmsAddress: ip + '80',//权限系统
    }
  }
  if (window.location.hostname == '127.0.0.1') {// 内网对应的地址
    return {
      pmsAddress: ip + '14810',
    }
  }
  if (window.location.hostname == '106.120.201.126') {  // 公司外网对应的地址
    return {
      pmsAddress: ip + '14810',
    };
  }
  return {
    // 现场内外网对应的端口
    pmsAddress: ip + '6105',
  };
};

//默认配置
const Settings = {
  navTheme: 'dark',
  primaryColor: '#1890ff',
  layout: 'top',
  contentWidth: 'Fixed',
  fixedHeader: true,
  fixSiderbar: false,
  pwa: false,
  headerHeight: 56,
  splitMenus: false,
  menuRender: false,
  menuHeaderRender: false,

  title: '前端软件安全管控',
  autoAddress,
};
export default Settings;
